import React from "react";
import { Card, HeavyText, LightText, PageTitle, HeaderWrapper, ArtistName } from './Style';

export default function Header() {
    return (
        <HeaderWrapper>

            <PageTitle>
                <ArtistName>BEN KAMEN</ArtistName>
            </PageTitle>

            <Card>
                <LightText>
                    <a href={"http://music.benkamen.com"} target={"_blank"} rel={"noreferrer"}>
                        MUSIC</a> { } 
                    <a href={"http://www.olympianoiseco.com"} target={"_blank"} rel={"noreferrer"}>APPS</a> {   }
                    <a href={"http://www.instagram.com/benkamen"} target={"_blank"} rel={"noreferrer"}>INSTAGRAM</a> { } 
                    <a href={"https://aemail.com/8q1j"}>CONTACT</a>
                </LightText>
            </Card>

        </HeaderWrapper>
    );
}
