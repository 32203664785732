import logo from './logo.svg';
import './App.css';
import { Helmet } from "react-helmet";
import Header from "./Header.js";
import clearcut from "./images/clear-cut-1.jpg";
import polaroid from "./images/ben-kamen-small.jpg"
import watershed from "./images/watershed-cover.jpg";
import { Router, Routes, Route, BrowserRouter } from "react-router-dom";
import { Card, ExtraLargeFont, HeavyItalicText, HeavyText, LightItalicText } from './Style';
import Iframe from 'react-iframe';

import WatershedPressKit from './Watershed-PressKit';

function App() {
  return (
    <div className="App" >

      <Helmet>
        <style>{"body {background-color: white; }"}</style>
      </Helmet>
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/watershed-presskit" element={ <WatershedPressKit />} />
      </Routes>
    </div>
  );
}

function Home() {
  return (
    <div>

      <br />
      <Card>
        <img src={polaroid} alt="ben kamen polaroid" width="100%" />
      </Card>

      <Card>
        <br />
        NEW ALBUM <br /><ExtraLargeFont><HeavyItalicText>WATERSHED</HeavyItalicText></ExtraLargeFont>
        <HeavyText>OUT NOW!</HeavyText><br />
        <HeavyText><a href="http://music.benkamen.com">Bandcamp</a></HeavyText>
        <HeavyText><a href="https://open.spotify.com/artist/7i1ICQUyZCvUwctRgDiz3w?si=LqX2VmjUTLafopeIEdmfAA">Spotify</a></HeavyText>
        <HeavyText><a href="https://music.apple.com/us/album/watershed/1622910317">Apple Music</a></HeavyText>
      </ Card>

      <br />
      <Card>
        <img src={watershed} alt="ben kamen - watershed album artwork" width="100%" />
      </Card>
      <br />
<Card>
      <Iframe
          src='https://bandcamp.com/EmbeddedPlayer/album=595682112/size=large/bgcol=ffffff/linkcol=63b2cc/tracklist=false/artwork=small/track=2007053731/transparent=true/'
          width="100%"
          height="120px"
          id="embed"
          frameBorder={0} 
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"/>
</Card>

<br/><br/>
      <Card>
        <LightItalicText>LISTEN TO <HeavyItalicText>"PURPLE SHADOWS"</HeavyItalicText></LightItalicText>
        <br />
        <br/>
        <Iframe
                    src='https://www.youtube.com/embed/HlUSTDT01Cw'
                    width="100%"
                    height="300px"
                    id="embed"
                    frameBorder={1}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" />
                    <br/>
                    <a href="https://open.spotify.com/track/6NpWarilQwR0Z3796sRfAX?si=26978ae465914e8b">Spotify</a>
                    <a href="https://music.apple.com/us/album/purple-shadows/1626552985?i=1626552986">Apple Music</a>
      </Card>
      <br />
      <br />
      <Card>
        <LightItalicText>LISTEN TO <HeavyItalicText>"MAKE BELIEVE"</HeavyItalicText></LightItalicText>
        <br />
        <br/>
        <Iframe
                    src='https://www.youtube.com/embed/kQr6N2O_RBA'
                    width="100%"
                    height="300px"
                    id="embed"
                    frameBorder={1}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" />
                    <br/>
                    <a href="https://open.spotify.com/track/6i10liYka0gXc5uZbB5VmF?si=2558a27fae404bc4">Spotify</a>
                    <a href="https://music.apple.com/us/album/make-believe-single/1629820480">Apple Music</a>
      </Card>
      <br /><br />

    </div>
  );
}

export default App;

///
<iframe allow="autoplay *; encrypted-media *;" frameborder="0" height="150" style="width:100%;max-width:660px;overflow:hidden;background:transparent;" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/purple-shadows/1622910317?i=1622910320"></iframe>
///
///
// <iframe style="border: 0; width: 100%; height: 120px;" src="https://bandcamp.com/EmbeddedPlayer/album=595682112/size=large/bgcol=ffffff/linkcol=63b2cc/tracklist=false/artwork=small/track=2007053731/transparent=true/" seamless><a href="https://music.benkamen.com/album/watershed">Watershed by ben kamen</a></iframe>
///