import logo from './logo.svg';
import './App.css';
import { Helmet } from "react-helmet";
import Header from "./Header.js";
import watershed from "./images/watershed-cover.jpg";
import Iframe from 'react-iframe';
import { ArtistName, AlbumName, Card, HeavyText, LightItalicText, HeavyItalicText, ExtraLargeFont, LargeFont, CopyCard } from './Style';
import benstudio1 from "./images/ben-studio-1.jpg";
import benstudio2 from "./images/ben-studio-2.jpg";
import clearcut from "./images/clear-cut-1.jpg";
import polaroid from "./images/ben-kamen-small.jpg"

function WatershedPressKit() {
    return (
        <div>

            <br />
            <br />
            <Card>
                <img src={watershed} alt="ben kamen - watershed album artwork" width="100%" />
            </Card>
            <br />

            <Card>
                <ExtraLargeFont>
                    <HeavyItalicText>WATERSHED</HeavyItalicText><br /> </ExtraLargeFont>
                <LargeFont>
                    <LightItalicText>AN ALBUM by <HeavyItalicText>BEN KAMEN</HeavyItalicText> <br />RELEASE DATE : <HeavyItalicText>JULY 15, 02022</HeavyItalicText></LightItalicText>
                </LargeFont>
                <br /><LightItalicText>
                    This album is not yet released, but feel free to share privately with folks who may enjoy it. 
                    </LightItalicText>
                    <br />


                    <HeavyText><ExtraLargeFont>•••• ALBUM PREVIEW ••••</ExtraLargeFont></HeavyText><br />
</Card>
                    <CopyCard>
                        by <a href="https://twitter.com/sophiefkemp">Sophie Kemp</a>
                        <br />
                        Over the past two decades, Ben Kamen has created music for a variety of formats and genres — from chamber music to multi-channel sound installations to folk and post-rock.  Originally from Ohio, Kamen has lived in Olympia, WA since 2006, where he teaches electronic music composition at The Evergreen State College and develops musical instrument apps, including the drum machine “Patterning.”
                        <br />
                        <br />
                        After a decade-plus of time away from writing and performing his own songs, Kamen started writing again during the early part of the pandemic.  Eager for a new sound palette to work with, Kamen took up the alto saxophone and the banjo, two instruments he approached with a beginner’s mindset and willingness to push outside of their idiomatic uses through studio experimentation.
                        <br /><br />

<LightItalicText>Keep reading below....</LightItalicText>
                    </CopyCard>

                    <br /><br />


                
                <br />
<Card>
                <Iframe
                    src='https://bandcamp.com/EmbeddedPlayer/album=595682112/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/tracklist=true/tracks=555982799,356228300,2007053731,23453315,2057074069,274064508,473220334,2889852013,12820871/esig=64dade992dee81504e0c8d029fdfe426/'
                    width="100%"
                    height="300px"
                    id="embed"
                    frameBorder={1}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" />
                <br />
                <Iframe
                    src='https://www.youtube.com/embed/HlUSTDT01Cw'
                    width="100%"
                    height="300px"
                    id="embed"
                    frameBorder={1}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" />
                <br />

                <br />
                <Iframe
                    src='https://www.youtube.com/embed/kQr6N2O_RBA'
                    width="100%"
                    height="300px"
                    id="embed"
                    frameBorder={1}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" />
                <br />

                <br />
                <a href="https://www.dropbox.com/sh/3sa3i5dk2v10pag/AAC_jVN3ex76PsUw0hUs81rta?dl=0">MP3 Download</a> <br />
                <a href="https://www.dropbox.com/sh/zppclz1acuuubd8/AADOwWznkfHJkPOEbwymZfyya?dl=0">Photos and Artwork</a>

                <br />
                <a href={"https://aemail.com/8q1j"}>CONTACT</a>
            </Card>
            <br />
            <br />
            <br />
            <CopyCard>
                <img src={polaroid} alt="ben kamen - polaroid" width="100%" />
                <br /><br />
                It took over two years for the explorations to coalesce into an album. Part of that had to do with health issues—Kamen started losing his voice, and a doctor confirmed that he had a paralyzed vocal cord.  “There is a lot of grief involved in receiving the news that the thing you’ve been putting off doing for the last decade might be unattainable.  I suppose the flip side of that grief was the resolution that if I gave up, I would be giving up permanently.”  On Watershed, Kamen learned how to sing again.
                <br /><br />
                What transpired is a record of lush songs with Kamen’s voice an instrument as important as a synthesizer or a guitar.  The saxophone became an oscillator, treated like a module in a synthesizer that could be layered and processed into a texture.  He strummed the banjo through delays and reverbs to create haunted chords.
                <br /><br />
                Look no further to see the results of these experiments with timbre than “Make Believe,” the first song to be written for the record. The song has a heavy, repetitive bass-line in an odd-meter, and Kamen takes the saxophone, pitch-shifts it down to double the bass, giving the song a dense, mossy texture, while electric guitar and saxophone layers harmonize in an ominous melody.
                <br /><br />
                “Throughout the writing of the songs that would become Watershed, I was taking daily walks through a trail near my house that winds through a temperate rainforest, full of old growth trees, ferns, and communities of fungus.”  The record’s thematic imagery were strongly informed by this environment. Watershed became a record that negotiates with the death and decay and beauty that exists within nature and in human life.
                <br />
                <br />
                <img src={clearcut} alt="ben kamen - clear cut forest" width="100%" />
                <br /><br />
                Watershed is also a study in memory, how we hold on to the past, how we let it go. Music, after all, is a memory device, a collage of fixed moments in time.  During the final stages of making the record Kamen participated in a songwriting workshop with Fleet Foxes’ Robin Pecknold. One of the first slides in his lecture read “certainty is necessary, but always temporary.” That state of mind laid the groundwork for Watershed’s lovely “Purple Shadows,” a song that captures a brief moment in Kamen’s relationship with his father, just months before he passed. It’s a song that grapples with memory in its most explicit form.
                <br /><br />
                Kamen performed, recorded and mixed the record himself at home, enlisting Josh Bonati (Sufjan Stevens, Jenny Hval, Amen Dunes) as a mastering engineer.
                <br /><br />
                Watershed will be available on digital formats on July 15, 2022.
                <br /><br />
            </CopyCard>


        </div>
    );
}

export default WatershedPressKit;


/*
function createMarkup() {
    return {__html: '<iframe style="border: 0; width: 400px; height: 803px;" src="https://bandcamp.com/EmbeddedPlayer/album=595682112/size=large/bgcol=ffffff/linkcol=0687f5/transparent=true/tracklist=true/tracks=555982799,356228300,2007053731,23453315,2057074069,274064508,473220334,2889852013,12820871/esig=64dade992dee81504e0c8d029fdfe426/" seamless><a href="https://music.benkamen.com/album/watershed">Watershed by ben kamen</a></iframe>'};
}
*/

//<iframe style="border: 0; width: 400px; height: 439px;" src="https://bandcamp.com/EmbeddedPlayer/album=595682112/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/tracklist=true/tracks=555982799,356228300,2007053731,23453315,2057074069,274064508,473220334,2889852013,12820871/esig=64dade992dee81504e0c8d029fdfe426/" seamless><a href="https://music.benkamen.com/album/watershed">Watershed by ben kamen</a></iframe>  

