import styled from "styled-components";

export const HeavyText = styled.span`
  font-weight: 900;
`;

export const LightItalicText = styled.span`
  font-weight: 300;
  font-style: italic;
`;

export const HeavyItalicText = styled.span`
  font-weight: 800;
  font-style: italic;
`;

export const LightText = styled.span`
  font-weight: 300;
  font-style: normal;
`;

export const PageTitle = styled.div`
  text-align: center;
  font-size: xx-large;
  padding: 0em;
  font-weight: 400;
`;


export const ArtistName = styled.div`
  text-align: center;
  font-size: xx-large;
  padding: 1em;
  font-weight: 800;
  font-style: italic;
`;


export const AlbumName = styled.div`
  text-align: center;
  font-size: xx-large;
  padding: 1em;
  font-weight: 700;
  font-style: italic;
`;

export const SmallFont = styled.span`
  font-size: small;
`;

export const LargeFont = styled.span`
  font-size: large;
`;

export const ExtraLargeFont = styled.span`
  font-size: x-large;
`;

export const Image = styled.img`
    max-width: 100%;
`;

export const Wrapper = styled.section`
  padding: 0.5em 1em;
`;

export const HeaderWrapper = styled(Wrapper)`
    padding: 0.5em 0.5em;
    color: black;
`;

export const Card = styled.div`
  box-sizing: border-box;
  max-width: 410px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

`;

export const CopyCard = styled(Card)`
text-align:justify;
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Input = styled.input`
  padding: 1rem;
  border: 1px solid #999;
  margin-bottom: 1rem;
  font-size: 0.8rem;
`;

export const Button = styled.button`
  background: white;
  border-color: black;
  border-radius: 0.25em;
  padding: 1rem;
  color: black;
  font-weight: 700;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 0.8rem;
`;

export const Logo = styled.img`
  width: 50%;
  margin-bottom: 1rem;
`;

export const FormError = styled.div`
  background-color: tomato;
  margin: 0.5rem 0.5rem;
  padding: 0.5rem 0.5rem;
  border-radius: 0.25em;
  color: white;
`;

export const ListItem = styled.li`
font-weight:800;
`;